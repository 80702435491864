<template>
  <div>
    <form-create
        :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
        :lista-usuarios="listaUsuarios"
        titulo="Novo Perfil"
        @refetch-data="refetchData"
    />

    <b-card
        no-body
        class="mb-0"
    >
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">
        Lista de Perfis
      </h5>
      <div class="m-2">
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
              />
              <b-button
                  variant="success"
                  @click="isAddNewSidebarActive = true"
              >
                <span class="text-nowrap">
                  Adicionar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchDados"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          :busy="isLoad"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"/>
            <strong> Carregando...</strong>
          </div>
        </template>

        <template #cell(name)="data">
          <b-link
              :to="{ name: 'editar-perfil', params: { name: data.item.name } }"
              class="font-weight-bold"
          >
            {{ data.item.name }}
          </b-link>
          <br/> <small>Usuários no perfil: {{ data.item.users.length }}</small>
        </template>
        <template #cell(permissions)="data">
          <b-media
              vertical-align="center"
              align="center"
          >

            {{ data.item.permissions.length }}
          </b-media>
        </template>

        <template #cell(users)="data">
          <b-avatar-group size="30px">
            <b-avatar
                v-for="user in data.item.users"
                :key="user.uuid"
                v-b-tooltip.hover.top="nomeFuncao(user)"
                size="32"
                class="pull-up"
                :src="user.imagem"
                :variant="`light-primary`"
                :text="avatarText(user.name)"
            />
          </b-avatar-group>

        </template>

        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <router-link :to="{ name: 'editar-perfil', params: { name: data.item.name } }">
            <feather-icon
                icon="EditIcon"
                size="16"
            />
          </router-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalItens"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  VBPopover,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import FormCreate from './FormCreate.vue'
import useItemList from './useItemList'
import perfisStoreModule from '../perfisStoreModule'

export default {
  components: {
    BAvatarGroup,
    FormCreate,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BFormInvalidFeedback,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const blankUserData = {
      name: '',
      users: [],
      permissions: [],
    }
    const PERFIS_APP_STORE_MODULE_NAME = 'app-perfis'

    // Register module
    if (!store.hasModule(PERFIS_APP_STORE_MODULE_NAME)) store.registerModule(PERFIS_APP_STORE_MODULE_NAME, perfisStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PERFIS_APP_STORE_MODULE_NAME)) store.unregisterModule(PERFIS_APP_STORE_MODULE_NAME)
    })

    const itemData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const isAddNewSidebarActive = ref(false)

    const {
      fetchDados,
      listaUsuarios,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteItem,
      update,

    } = useItemList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    return {
      isAddNewSidebarActive,
      listaUsuarios,
      deleteItem,
      update,
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      refFormObserver,
      getValidationState,
      resetForm,
      itemData,

    }
  },
  data() {
    return {
      dadosItem: {
        uuid: '',
        name: '',
        users: [],
        permissions: [],
      },
      modalShow: false,

    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-perfis'].load
    },

  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,

  },
  methods: {
    nomeFuncao(item) {
      const nome = item.nome ? item.nome : item.name
      return `${nome}`
    },
    avatarText(value) {
      if (!value) {
        return ''
      }
      const nameArray = value.split(' ')
          .slice(0, 2)
      const initials = nameArray
          .map((word) => word.charAt(0)
              .toUpperCase())
          .join('')

      return initials
    },
    modalExcluir(item) {
      this.dadosItem.nome = item.nome
      this.dadosItem.uuid = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },
    excluirItem(dados) {
      this.deleteItem(dados)
    },
    salvarItemEditado(dadosItem) {
      this.$root.$emit('bv::hide::modal', 'modal-editar')
      this.update(dadosItem)
    },
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
